import React, {useEffect, useRef, useState} from "react"
import {graphql} from "gatsby";
import {
  Col,
  Container,
  Row,
  ListGroup,
  ListGroupItem, Spinner
} from 'reactstrap'
import SelectSearch, {fuzzySearch} from 'react-select-search';
import * as turf from '@turf/turf';
import {Marker, Popup, TileLayer} from "react-leaflet";
import {FaMapMarkerAlt} from 'react-icons/fa'

import Map from "../components/map"
import Layout from "../components/layout"
import PageTitle from '../components/pageTitle'
import SEO from "../components/seo"
import {LangContext} from "../components/kbusWrapper";

import MapMarkerCurrentIcon from "../components/mapMarkerCurrentIcon"
import MapMarkerParadaIcon from "../components/mapMarkerParadaIcon"
import AnimateHeight from 'react-animate-height';

const centroBarakaldo = [43.27712187931624, -2.9845749134823794];

const ProximoBusPage = ({data}) => {

  const [geolocationAvailable, setGeolocationAvailable] = useState(null);
  const [currentPositionLat, setCurrentPositionLat] = useState(null);
  const [currentPositionLon, setCurrentPositionLon] = useState(null);
  const [currentPosition, setCurrentPosition] = useState([]);
  const [nearestParadas, setNearestParadas] = useState([]);
  const [selectedParada, setSelectedParada] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  //const [centroMapa, setCentroMapa] = useState(centroBarakaldo);
  const [estimaciones, setEstimaciones] = useState(null);
  const [processing, setProcessing] = useState(false);

  let paradas = [];
  data.lineas.edges.forEach((linea) => {
    linea.node.rutas.forEach((ruta) => {
      const lineaParadas = ruta.paradas.map((parada) => {
        return {
          coord: parada.coord,
          label: parada.label,
          nombre: parada.nombre,
          linea: linea.node.nombre,
          direccion: ruta.nombre
        }
      });
      paradas = paradas.concat(lineaParadas);
    })
  });

  const refs = [];
  paradas.forEach((parada) => refs[parada.label] = React.createRef());
  const markersRef = useRef(refs);

  const closePopups = () => {
    markersRef.current.forEach((m) => {
      m.current.closePopup()
    });
  }

  useEffect(() => {
    if ("geolocation" in navigator) {
      setGeolocationAvailable(true);
    } else {
      setGeolocationAvailable(false);
    }
    navigator.geolocation.getCurrentPosition((position) => {
      console.log(position);
      setCurrentPositionLat(position.coords.latitude);
      setCurrentPositionLon(position.coords.longitude);
    }, (error) => {
      setGeolocationAvailable(false);
    });
  }, []);

  useEffect(() => {
    if (currentPositionLat !== null && currentPositionLon !== null) {
      const from = turf.point([currentPositionLon, currentPositionLat]);
      paradas.sort((parada1, parada2) => {
        const toParada1 = turf.point([parada1.coord.lon, parada1.coord.lat]);
        const toParada2 = turf.point([parada2.coord.lon, parada2.coord.lat]);
        return turf.distance(from, toParada1) - turf.distance(from, toParada2);
      })

      const nearestParadas = paradas.slice(0, 6);

      //setCentroMapa([currentPositionLat, currentPositionLon]);
      setNearestParadas(nearestParadas);
      setCurrentPosition(<Marker icon={MapMarkerCurrentIcon()} position={[currentPositionLat, currentPositionLon]}/>);

    } else {
      // No position
    }
  }, [currentPositionLat, currentPositionLon])

  useEffect(() => {
    setInterval(() => searchParadaTimes(), 30000)
  }, []);

  useEffect(() => {
    setEstimaciones(null);
    if (selectedParada !== null) {
      searchParadaTimes();
      markersRef.current[selectedParada.label].current.openPopup();
    }
  }, [selectedParada]);

  const rendererParadas2 = (props, option, snapshot, className) => {
    const parada = option.content;
    return (
      <button {...props} className={`${className} d-flex flex-row justify-content-between align-items-center py-1 px-3`} type="button">
        <span className="kbus-parada-linea-font">{parada.nombre}</span>
        <div className="d-flex flex-column align-items-end">
          <span className="kbus-parada-linea-font py-0 text-capitalize">{parada.linea.toLowerCase()}</span>
          <span className={`kbus-parada-linea-sub-font py-0 ${snapshot.selected ? '' : 'text-muted'}`}>{parada.direccion}</span>
        </div>
      </button>
    );
  }

  const selectSearchChange = (value) => {
    const parada = paradas[value];
    setSelectedIndex(value);
    setSelectedParada(parada);
  }

  const searchParadaTimes = () => {
    if (selectedParada === null) return;
    setProcessing(true)
    const xhr = new XMLHttpRequest()
    const node = selectedParada.label
    xhr.open("GET", `/getNodeTime.php?node=${node}`)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        return;
      } else {
        setProcessing(false)
      }
      if (xhr.status === 200) {
        try {
          setEstimaciones(JSON.parse(xhr.responseText))
        } catch (e) {
          setEstimaciones([])
        }
      } else {
        setEstimaciones([])
      }
    };
    xhr.send()
  }

  return (
    <LangContext.Consumer>
      {context => (
        <Layout>
          <SEO title={context.translate(data, 'proximoBus', 'titulo')}/>
          <Container className="py-5 mb-5">
            <PageTitle title={context.translate(data, 'proximoBus', 'titulo')}/>

            <Row className="pt-4">
              <Col xs={{size: 12, offset: 0}} lg={{size: 6, offset: 3}}>
                  <ListGroup>
                    <ListGroupItem className="d-flex flex-row justify-content-between align-items-center py-2 px-3 kbus-list-header" style={{'borderBottomWidth': nearestParadas.length > 0 ? '1px' : '0' }}>
                      <span className="kbus-parada-linea-font">
                        {
                          nearestParadas.length === 0 ?
                            (geolocationAvailable ===  null || geolocationAvailable ? context.translate(data, 'proximoBus', 'buscandoParadas') : context.translate(data, 'proximoBus', 'localizacionNoDisponible') ):
                            context.translate(data, 'proximoBus', 'paradasCercanas')
                        }
                      </span>
                      <FaMapMarkerAlt style={{color: "#BB2218"}}/>
                    </ListGroupItem>
                    { geolocationAvailable === false && (
                      <ListGroupItem className="d-flex flex-row justify-content-between align-items-center py-1 px-3">
                        <span className="kbus-parada-linea-font text-muted text-muted">{context.translate(data, 'proximoBus', 'localizacionTip')}</span>
                      </ListGroupItem>
                    )}
                    <AnimateHeight
                      duration={800}
                      delay={100}
                      easing={"ease-in"}
                      height={ nearestParadas.length > 0 ? 'auto' : 0 }
                    >
                    { nearestParadas.map((parada, index) => {
                          const selected = selectedParada !== null && selectedParada.label === parada.label && selectedParada.direccion === parada.direccion;
                          return (
                            <ListGroupItem key={index}
                                           className={`${selected ? 'kbus-parada-selected' : ''} d-flex flex-row justify-content-between align-items-center py-1 px-3`}
                                           onClick={() => {if (processing) return; setSelectedParada(parada); setSelectedIndex(-1)}}
                            >
                              <span className="kbus-parada-linea-font">{parada.nombre}</span>
                              <div className="d-flex flex-column align-items-end">
                                <span className="kbus-parada-linea-font py-0 text-capitalize">{parada.linea.toLowerCase()}</span>
                                <span className={`${selected ? '' : 'text-muted'} kbus-parada-linea-sub-font py-0`}>{parada.direccion}</span>
                              </div>
                            </ListGroupItem>
                          )
                        }
                      )
                    }
                    </AnimateHeight>
                  </ListGroup>
              </Col>
            </Row>

            <Row className="pt-4">
              <Col xs={{size: 12, offset: 0}} lg={{size: 6, offset: 3}}>
                <SelectSearch
                  value={selectedIndex}
                  onChange={selectSearchChange}
                  search
                  filterOptions={fuzzySearch}
                  options={paradas.map((parada, index) => {
                    return {
                      value: index,
                      name:`${parada.nombre}`,
                      content: parada
                    }
                  })}
                  placeholder={context.translate(data, 'proximoBus', 'seleccionaTuParada')}
                  renderOption={rendererParadas2}
                />
              </Col>
            </Row>

            {/*
            <Row className="pt-4">
              <Col xs={{size: 12, offset: 0}} lg={{size: 6, offset: 3}} className="d-flex flex-row justify-content-center align-items-center">
                <Button color="success" className="px-5" onClick={searchParadaTimes} disabled={(selectedIndex === -1 && selectedParada === null) || processing}>
                  {processing ?
                    <Spinner size="sm" style={{"verticalAlign": "middle", "marginBottom": "3px"}}/> :
                    "Buscar"
                  }
                </Button>
              </Col>
            </Row>
            */}
          </Container>

          <Container>
            <Row>
              <Col xs={12} md={{size: 10, offset: 1}} lg={{size: 8, offset: 2}}>
                {estimaciones !== null ?
                  (
                    estimaciones.length > 0 ?
                      estimaciones.map((estimacion) => {
                        const tiempos = [estimacion.vh_first];
                        if (Object.keys(estimacion).indexOf("vh_second") !== -1) {
                          tiempos.push(estimacion.vh_second);
                        }
                        return tiempos.map((tiempo, index) => (
                          <div key={index} className={`${index > 0 ? '': 'border-top'} w-100 px-3 py-3 d-flex flex-column flex-lg-row justify-content-between align-items-center`}>
                            <div className="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start align-items-center">
                              <span className="text-muted">{estimacion.linea}</span>
                              <span className="d-none d-lg-inline">&nbsp;/&nbsp;</span>
                              <span className="font-weight-bold">{selectedParada.nombre}</span>
                            </div>
                            <span className="text-danger font-weight-bold">{Math.ceil(tiempo.tiempo / 60)} min</span>
                          </div>
                          )
                        )
                      }) :
                      (
                        <div className="w-100 py-3 border-top d-flex flex-row justify-content-around align-items-center">
                          <span className={"text-center text-danger"}>{context.translate(data, 'proximoBus', 'noResultados')}</span>
                        </div>
                      )
                  ) : null
                  /*(
                    <div className="w-100 py-3 border-top d-flex flex-row justify-content-around align-items-center">
                      <span className={"text-muted"}>Selecciona una parada para obtener el tiempo del próximo bus.</span>
                    </div>
                  )*/
                }
              </Col>
            </Row>
          </Container>

          <Container>
            <Row className="pt-3 mb-3 pb-5">
              <Col xs={12} style={{height: '500px'}}>
                <Map centroMapa={centroBarakaldo} zoomMapa={13} styleMapa={{height: '100%'}}>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {currentPosition}
                  {
                    paradas.map((parada, index) => {
                      return (
                        <Marker key={index}
                                position={[parada.coord.lat, parada.coord.lon]}
                                icon={MapMarkerParadaIcon()}
                                eventHandlers={{
                                  click: (e) => {
                                    setSelectedIndex(-1);
                                    setSelectedParada(parada);
                                  },
                                }}
                                ref={markersRef.current[parada.label]}
                        >
                              <Popup>
                                {
                                  processing
                                    ? <div className={"d-flex flex-column justify-content-center align-item-center"}><Spinner size="sm" color="danger"/></div>
                                    : (
                                      <div>
                                        <span className="font-weight-bold">{parada.nombre}</span>
                                        <br/>
                                        <span className={"text-capitalize"}>{parada.linea.toLowerCase()}</span>
                                        <br/>
                                        {
                                          estimaciones && estimaciones.length > 0 && !processing
                                            ? estimaciones.map((estimacion) => {
                                              const tiempos = [estimacion.vh_first];
                                              if (Object.keys(estimacion).indexOf("vh_second") !== -1) {
                                                tiempos.push(estimacion.vh_second);
                                              }
                                              return (
                                                <div className={"mt-2"}>
                                                  {
                                                    tiempos.map((tiempo, index) => (
                                                      <span key={index}>
                                                        <span className={"text-capitalize"}>{estimacion.linea.toLowerCase()}</span>:&nbsp;<span className={"text-danger"}>{Math.ceil(tiempo.tiempo / 60)} min</span>
                                                        <br/>
                                                      </span>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            })
                                            : (!processing
                                              ? <span className={"d-block text-danger mt-2"}>{context.translate(data, 'proximoBus', 'noResultados')}</span>
                                              : null
                                            )
                                        }
                                      </div>
                                    )
                                }
                              </Popup>
                        </Marker>
                      )
                    })
                  }
                </Map>
              </Col>
            </Row>
          </Container>
        </Layout>
      )}
    </LangContext.Consumer>
  )
}

export default ProximoBusPage

export const pageQuery = graphql`  
  query {
    proximoBus: strapiProximoBus {
      titulo
      localizacionNoDisponible
      seleccionaTuParada
      localizacionTip
      noResultados
      buscandoParadas
      paradasCercanas
    }
    proximoBus_eus: strapiProximoBusEus {
      titulo
      localizacionNoDisponible
      seleccionaTuParada
      localizacionTip
      noResultados
      buscandoParadas
      paradasCercanas
    },
    lineas: allStrapiLineas {
      edges {
        node {
          label
          nombre
          rutas {
            direccion
            nombre
            paradas {
              nombre
              label
              coord {
                lat
                lon
              }
            }
          }
        }
      }
    }
  }
`
